<template>
    <div class="px-5">
      <h2 class="is-size-1 has-text-centered mb-3">
          Formularz kontaktowy
      </h2>
      <p class="is-size-5 has-text-centered mb-6">opisz jaka współpraca Cię interesuje</p>
      <div class="columns">
          <div class="column">
              <div class="field">
                  <label class="label">Imię</label>
                  <div class="control has-icons-left">
                    <input class="input" type="text" placeholder="" v-model="name">
                    <span class="icon is-small is-left">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="form-icon svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
                    </span>
                  </div>
                  <p class="help is-danger" v-if="nameError">Wpisz swoje imię</p>
                </div>
          </div>
          <div class="column">
              <div class="field">
                  <label class="label">E-mail</label>
                  <div class="control has-icons-left">
                    <input class="input" type="email" placeholder="" v-model="email">
                    <span class="icon is-small is-left">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" class="form-icon svg-inline--fa fa-envelope fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                    </span>
                  </div>
                  <p class="help is-danger" v-if="emailError">{{ emailErrorText }}</p>
              </div>
          </div>
          <div class="column">
              <div class="field">
                  <label class="label">Telefon</label>
                  <div class="control has-icons-left">
                    <input class="input" type="text" placeholder="" v-model="phone">
                    <span class="icon is-small is-left">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" class="form-icon svg-inline--fa fa-phone-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                    </span>
                  </div>
                  <p class="help is-danger" v-if="phoneError">Podaj swój numer telefonu</p>
              </div>
          </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Rodzaj współpracy</label>
            <div class="control has-icons-left">
              <div class="select is-fullwidth">
                <select v-model="type">
                  <option disabled selected hidden>Wybierz rodzaj</option>
                  <option>Wykonanie strony - wizytówki</option>
                  <option>Wykonanie sklepu internetowego</option>
                  <option>Stała współpraca - developer</option>
                  <option>Stała wspołpraca - grafika komputerowa</option>
                </select>
                <span class="icon is-small is-left">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" class="form-icon svg-inline--fa fa-phone-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Ilość podstron</label>
            <div class="control has-icons-left">
              <div class="select is-fullwidth">
                <select v-model="value">
                  <option disabled selected hidden>Wybierz</option>
                  <option>1 - 5</option>
                  <option>5 - 10</option>
                  <option>ponad 10</option>
                </select>
                <span class="icon is-small is-left">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" class="form-icon svg-inline--fa fa-phone-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Wymiar zaangażowania</label>
            <div class="control has-icons-left">
              <div class="select is-fullwidth">
                <select v-model="contribution">
                  <option disabled selected hidden>Wybierz przedział</option>
                  <option>od 10 godzin w tygodniu</option>
                  <option>od 10 do 20 godzin w tygodniu</option>
                  <option>powyżej 20 godzin w tygodniu</option>
                </select>
                <span class="icon is-small is-left">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" class="form-icon svg-inline--fa fa-phone-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
          <label class="label">Wiadomość</label>
          <div class="control has-icons-left">
            <textarea class="textarea" placeholder="" v-model="message"></textarea>
            <span class="icon is-small is-left">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="comment" class="form-icon svg-inline--fa fa-comment fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32z"></path></svg>
            </span>
          </div>
          <p class="help is-danger" v-if="messageError">Wpisz treść wiadomości</p>
      </div>
        
      <div class="field">
          <div class="control">
            <label class="checkbox">
              <input type="checkbox" v-model="consent">
              Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych przez Sebastian Myrcha, w szczególności na otrzymywanie od niego informacji handlowych.
            </label>
          </div>
          <p class="help is-danger" v-if="consentError">Wyraź zgodę, aby wysłać wiadomość</p>
      </div>
        
        <div class="field is-grouped">
          <div class="control">
            <button class="button" @click="validate">Wyślij</button>
          </div>
          <div class="control">
            <button class="button reverse" @click="$emit('toggleForm')">
              Anuluj
            </button>
          </div>
        </div>
        <Modal v-if="emailSent" 
          :modalTitle='modalTitle' 
          :modalText='modalText'
          :modalType='modalType'
          :showModal='showModal' 
          @toggleModal='toggleModal'
          />
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
  name: 'ContactForm',
  components: {Modal},
  data() {
    return {
      name: '',
      phone: '',
      email: '',
      message: '',
      type: 'Wybierz rodzaj',
      value: 'Wybierz przedział',
      contribution: 'Wybierz przedział',
      consent: false,
      nameError: false,
      phoneError: false,
      emailError: false,
      emailErrorText: 'Podaj poprawny adres e-mail',
      messageError: false,
      consentError: false,
      emailSent: false,
      showModal: true,
      modalTitle: 'Mail został wysłany',
      modalText: 'Dziękujemy za wysłanie widomości. Wkrótce się skontaktujemy.',
      modalType: 'is-success'
    }
  },
  methods: {
    validate() {
      this.nameError = this.name === '' ? true : false
      this.emailError = this.email === '' || !this.validEmail(this.email)  ? true : false
      this.phoneError = this.phone === '' ? true : false
      this.messageError = this.message === '' ? true : false
      this.consentError = !this.consent ? true : false
      if(!this.nameError && !this.emailError && !this.phoneError && !this.messageError && !this.consentError) {
        this.sendForm()
      }
    },
    sendForm() {
      const formData = {
        'name': this.name, 
        'email': this.email, 
        'phone': this.phone,
        'creditType': this.type,
        'value': this.value,
        'contribution': this.contribution,
        'message': this.message

        }
      this.postData('http://localhost/PHPMailer/mail.php', formData)
      .then(data => {
        console.log(1, data); // JSON data parsed by `data.json()` call
        if(data !== data) {
          this.showSuccessInfo()
        }
        else {
          this.showErrorInfo()
        }
      });
    },
    // Example POST method implementation:
    async postData(url = '', data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          //'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      //console.log(response, response.text(), response.json())
      return response.ok === true ? response.json() : null; // parses JSON response into native JavaScript objects
    },
    toggleModal() {
      this.showModal=!this.showModal
    },
    clearForm() {
      this.name = ''
      this.phone = ''
      this.email = ''
      this.message = ''
      this.type = 'Wybierz rodzaj'
      this.value = 'Wybierz przedział'
      this.contribution = 'Wybierz przedział'
      this.consent = false
    },
    showSuccessInfo() {
      this.modalType = 'is-success'
      this.modalTitle = 'Mail został wysłany',
      this.modalText = 'Dziękujemy za wysłanie widomości. Wkrótce się skontaktujemy.',
      this.showModal = true
      this.emailSent = true
      this.clearForm()
    },
    showErrorInfo() {
      this.modalType = 'is-danger'
      this.modalTitle = 'Mail nie został wysłany',
      this.modalText = 'Nie udało się wysłać wiadomości. Proszę spróbować ponownie.',
      this.showModal = true
      this.emailSent = true
    },

    validateEmail(email) {
      if (!validEmail(email)) {
        this.emailError = true
        this.emailErrorText = 'Wpisz poprawny adres e-mail'
      }
    },

    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
}
</script>


