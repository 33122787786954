<template>
  <div class="modal" :class="showModal ? 'is-active' : ''">
      <div class="modal-background"></div>
      <div class="modal-content">
        <article class="message pb-2" :class="modalType ? modalType : 'is-success'">
          <div class="message-header">
            <p>{{ modalTitle }}</p>
            <button class="delete" aria-label="delete" @click="$emit('toggleModal')"></button>
          </div>
          <div class="message-body my-3">
            {{ modalText }}
          </div>
        </article>
      </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props:{
        modalTitle: String,
        modalText: String,
        showModal: Boolean,
        modalType: String
    },

    methods: {
        
    }
}
</script>

<style>

</style>