<template>
  <div class="home my-6 pt-6">
        <section id="projekty" class="section my-6 gradient-bg">
            <div class="container mt-5">
                <div class="has-text-centered">
                    <h2 class="is-size-2 mb-6">Projekty</h2>
                    <p class="max-width-75 is-size-4 my-5 centered">
                        Przykładowe realizacje. Strony internetowe, aplikacje,
                        które są przyjemne wizualnie, 
                        zorientowane na użytkownika i łatwe w użyciu.</p>
                </div>
                <div class="projects">
                    <transition-group name="fade" mode="out-in" appear="">
                        <div v-for="project in projects" :key="project.id" class="mb-6">
                            <Project 
                                :name = "project.name" 
                                :image = "project.image"
                                :link = "project.link"
                                :text = "project.text"
                                :position = "project.position"
                                :color = "project.color"
                            />
                        </div>
                    </transition-group>
                </div>
            </div>
        </section>

        <section class="section my-6" id="o-mnie">
            <div class="container mt-5">
                <div class="has-text-centered">
                    <h2 class="is-size-2 mb-6">W czym mogę pomóc?</h2>
                    <div class="columns">
                        <div class="column is-4">
                            <h3 class="is-size-3 my-5">UX/UI</h3>
                            <img src="@/assets/images/ux.svg" height="150" width="105" alt="User Experience">
                            <ul class="item-list mt-6">
                                <li>Projektowanie użytecznych interfejsów użytkownika</li>
                                <li>Ocena i wdrażanie poprawek w istniejących rozwiązaniach</li>
                            </ul>
                        </div>
                        <div class="column is-4">
                            <h3 class="is-size-3 my-5">Grafika</h3>
                            <img src="@/assets/images/design.svg" height="150" width="150" alt="Projekty graficzne">
                            <ul class="item-list mt-6">
                                <li>Projektowanie logo</li>
                                <li>Projekty materiałów reklamowych</li>
                                <li>Projekty stron internetowych</li>
                                <li>Grafiki do mediów społecznościowych</li>
                            </ul>
                        </div>
                        <div class="column is-4">
                            <h3 class="is-size-3 my-5">Programowanie</h3>
                            <img src="@/assets/images/developer.svg" height="150" width="105" alt="Programowanie">
                            <ul class="item-list mt-6">
                                <li>Frontend aplikacji</li>
                                <li>Responsywne strony internetowe</li>
                                <li>Kodowanie projektów graficznych</li>
                                <li>CMS Wordpress</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section mt-6 pb-6 light-bg">
            <div class="container my-6">
                <div class="has-text-centered">
                    <h2 class="is-size-2 mb-6">Z jakich technologii korzystam?</h2>
                    <SwitchButton 
                        :leftText='leftText' 
                        :rightText='rightText' 
                        :position='position' 
                        @toggleSwitch='toggleSwitch'
                    />
                    <transition-group name="fade" mode="out-in" class="columns is-multiline is-mobile tools mt-6" appear="">
                        <div class="column is-6-mobile is-3-tablet is-2-desktop" v-for="tool in toolsFiltered" :key="tool.id" >
                            <Tool :name="tool.name" :image="tool.image"/>
                        </div>
                    </transition-group>
                </div>
                
            </div>
        </section>
        
        <section id="kontakt" class="section mt-4">
            <div class="container">
                <div class="columns">
                    <div class="column is-8">
                        <h3 class="is-size-3 mb-4">
                            Szukasz frontend developera? <br>
                            Szukasz osoby do stałej współpracy?
                        </h3>
                        <p class="is-size-4">Jestem otwarty na propozycje.</p>
                        <button class="button cta my-6" @click="toggleForm"><span>Kontakt</span></button>
                    </div>
                    <div class="column is-size-4 is-4 has-text-right py-6">
                        <a href="tel:+48662838987">+48 662 838 987</a><br>
                        <a href="mailto:Sebastian.Myrcha@gmail.com">Sebastian.Myrcha@gmail.com</a> 
                    </div>
                </div>
            </div>
        </section>
            <div class="container">
                <div class="contact">
                    <transition name="resize" v-if="isFormActive" mode="out-in" appear class="contact-form">
                        <ContactForm @toggleForm='toggleForm'/>
                    </transition>
                </div>
            </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SwitchButton from '@/components/SwitchButton.vue'
import ContactForm from '@/components/ContactForm.vue'
import Tool from '@/components/Tool.vue'
import Project from '@/components/Project.vue'
import Html from '@/assets/images/tools/Html.svg'
import Css from '@/assets/images/tools/Css.svg'
import Js from '@/assets/images/tools/Js.svg'
import Vue from '@/assets/images/tools/Vue.svg'
import Nuxt from '@/assets/images/tools/Nuxt.svg'
import React from '@/assets/images/tools/React.svg'
import Sass from '@/assets/images/tools/Sass.svg'
import Bootstrap from '@/assets/images/tools/Bootstrap.svg'
import Bulma from '@/assets/images/tools/Bulma.svg'
import Node from '@/assets/images/tools/Node.svg'
import Php from '@/assets/images/tools/Php.svg'
import Laravel from '@/assets/images/tools/Laravel.svg'
import Python from '@/assets/images/tools/Python.svg'
import Django from '@/assets/images/tools/Django.svg'
import Postgresql from '@/assets/images/tools/Postgresql.svg'
import MongoDb from '@/assets/images/tools/Mongodb.svg'

export default {
  name: 'Home',
  components: {
    SwitchButton,
    Tool,
    Project,
    ContactForm
  },
  data() {
      return {
        leftText: 'Frontend',
        rightText: 'Backend',
        position: 'left',
        category: 'frontend',
        isFormActive: false,
        tools: [
            {id: 1, name: 'HTML 5', image: Html, category: 'frontend'}, 
            {id: 2, name: 'CSS 3', image: Css, category: 'frontend'},
            {id: 3, name: 'Java Stript', image: Js, category: 'frontend'},
            {id: 4, name: 'Vue', image: Vue, category: 'frontend'}, 
            {id: 5, name: 'Nuxt', image: Nuxt, category: 'frontend'}, 
            {id: 6, name: 'React', image: React, category: 'frontend'},
            {id: 7, name: 'Sass', image: Sass, category: 'frontend'},
            {id: 8, name: 'Booytstrap', image: Bootstrap, category: 'frontend'},
            {id: 9, name: 'Bulma', image: Bulma, category: 'frontend'},
            {id: 10, name: 'Nodejs', image: Node, category: 'backend'},
            {id: 11, name: 'PHP', image: Php, category: 'backend'},
            {id: 12, name: 'Laravel', image: Laravel, category: 'backend'},
            {id: 13, name: 'Python', image: Python, category: 'backend'},
            {id: 14, name: 'Django', image: Django, category: 'backend'},
            {id: 15, name: 'Postgresql', image: Postgresql, category: 'backend'},
            {id: 16, name: 'Mongodb', image: MongoDb, category: 'backend'},
        ],
        projects: [
            {   
                id: 1, 
                name:"STH", 
                text: "Strona internetowa Siedleckiego Towarzystwa Hokejowego", 
                image: require("@/assets/images/projects/sth.png"), 
                link: "#",
                position: "top",
                color: "blue"
            },
            {   
                id: 2, 
                name:"STH2", 
                text: "Strona internetowa Siedlceckiego Towarzystwa Hokejowego", 
                image: require("@/assets/images/projects/sth.png"), 
                link: "#",
                position: "bottom",
                color: "black"
            },
            {   
                id: 3, 
                name:"STH3", 
                text: "Strona internetowa Siedlceckiego Towarzystwa Hokejowego", 
                image: require("@/assets/images/projects/sth.png"), 
                link: "#",
                position: "top",
                color: "green"
            },
            {   
                id: 4, 
                name:"STH3", 
                text: "Strona internetowa Siedlceckiego Towarzystwa Hokejowego", 
                image: require("@/assets/images/projects/sth.png"), 
                link: "#",
                position: "top",
                color: "green"
            },
        ]
      }
  },
  methods: {
    toggleSwitch() {
      this.position = this.position==='left' ? 'right' : 'left'
      this.category = this.category==='frontend' ? 'backend' : 'frontend'
    },
    toggleForm() {
      this.isFormActive = !this.isFormActive
    },
  },
  computed: {
      toolsFiltered: function () {
          return this.tools.filter((item) => {return item.category === this.category})
      }
  }
}
</script>
<style>

.fade-enter-from, .fade-leave-to {
  opacity: 0;
  transform: scale(0.6);
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
  transform: scale(1);
}

.fade-enter-active {
  transition: all .4s ease-in-out;
}

.fade-leave-active {
  transition: all .4s ease-in-out;
  position: absolute;
}

.fade-move {
    transition: all .3s ease;
}

.contact {
  position: relative;
}

.resize-enter-active, .resize-leave-active {
    width: inherit;
    transition: all 0.1s ease-in-out;
}

.resize-enter, .resize-leave-to {
    opacity: 0;
    max-height: 0;
}

</style>
