<template>
  <transition name="slide" appear>
    <div class="cookie-info" v-if="infoShow">
      <div class="cookie-content">
            <span class="cookie-icon"></span><p class="cookie-text">Zjadam Twoje ciasteczka. Jeśli chcesz poznać treści na mojej stronie, upiecz ich więcej. A jeśli nie, przeczytaj o Polityce Prywatności Twoich ciasteczek.</p>
            <button class="button cookie-button" id="cookie-button" @click="hideCookieInfo">OK</button>
            <a href="#" class="button cookie-button">Szczegóły</a>
        </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'CookieInfo',
  data() {
    return {
      infoShow: false
    }
  },
  methods: {        

        showCookieInfo() {
            this.infoShow = true
        },

        hideCookieInfo() {
            this.infoShow = false
            this.saveCookie()
            console.log(this.infoShow)
        },

        getCookie(name) {
           const value = `; ${document.cookie}`
           const parts = value.split(`; ${name}=`)
           if (parts.length === 2) return parts.pop().split(';').shift()
        },

        saveCookie() {
            document.cookie = 'cookie-accepted=true; max-age=31536000;'
        }
  },
  mounted () {
    const IsCookie = this.getCookie('cookie-accepted')
    this.infoShow = IsCookie ?  false : true
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.cookie-info {
  position: fixed;
  min-width: 100%;
  height: auto;
  bottom: 0px;
  background-color: var(--primary-color);
  color: #fff;
  z-index: 9999;
}

.cookie-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.75em auto;
  font-size: 0.9em;
}

.cookie-icon::before {
  content: '';
  display: inline-block;
  background-image: url("../assets/icons/cookie-bite.svg");
  height: 32px;
  width: 32px;
  margin-right:12px;
}

.footer a.cookie-button, .button.cookie-button {
  padding: 0.25em 0.75em;
  margin: 0.25em 0 0.25em 1em;
  border: 1px solid #fff;
  font-size: 1em;
  background-color: var(--primary-color);
  color: #fff;
}

.footer a.cookie-button:hover, .button.cookie-button:hover {
  background-color: #fff;
  color: var(--primary-color);
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.8s ease-out;
}

.slide-enter,
.slide-leave-to
 {
  bottom: -200px;
}

</style>
