<template>
    <div class="project box mb-4" :class="color">
        <a :href="link">
            <img :src="image" :alt="text" class="project-image">
            <div class="overlay"></div>
            <div class="project-title" :class="position">
              <h3 class="is-size-5">{{ name }}</h3>
              <p>
                {{ text }}
              </p>
            </div>
        </a>
    </div>
</template>

<script>

export default {
  name: 'Project',
  props: {
    image: String,
    name: String,
    text: String,
    position: String,
    link: String,
    color: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.project {
  border-radius: 2em;
  position: relative;
  overflow: hidden;
  max-width: 630px;
  margin: auto;
  padding: 0!important;
  transition: all 0.3s ease-in-out;
}

.project:hover {
  transform: scale(1.04);
}

.project a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.project-image {
  position: relative;
  bottom: -2.5em;
  right: -2em;
}

.project-title {
  position: absolute;
  left: 2em;
  z-index: 30;
  font-size: 2rem;
}

.top {
  top: 2em;
}

.bottom {
  bottom: 2em;
}

.blue, .blue a {
  background-color: #223a7b!important;
  color: #fff!important
}

.green, .green a {
  background-color: #32aa4b!important;
  color: #fff!important
}

.black, .black a {
  background-color: #202020!important;
  color: #fff!important
}

.overlay {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 20;
}

@media screen and (max-width: 768px) {
    .overlay {
      background-color: rgba(0, 0, 0, 0.4);
    }

    .project-title {
      left: 1.5em;
      font-size: 1.5rem;
    }
}
</style>
