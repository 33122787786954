import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PrivatePolicy from '../views/PrivatePolicy'

Vue.use(VueRouter)
const companyName = 'Sebastian Myrcha - '
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: true,
    meta: {title: companyName+'portfolio'}
  },
  {
    path: '/polityka-prywatnosci',
    name: 'PrivatePolicy',
    component: PrivatePolicy,
    props: true,
    meta: {title: companyName+'Polityka Prywatności'}
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
