<template>
  <div id="app">
    <Header :mode='mode' @toggleSwitchMode='toggleSwitchMode'/>
    <Intro :mode='mode'/>
    <main class="mt-6">
      <transition name="fade" mode="out-in">
            <router-view/>
      </transition>
    </main>
    <Footer copyright="© 2021 Sebastian Myrcha"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Intro from '@/components/Intro.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Intro,
    Footer
  },
  data(){
    return {
      mode: 'light'
    }
  },
  methods: {
    toggleSwitchMode() {
      this.mode = this.mode === 'light' ? 'dark' : 'light'
      this.darkModeActivate()
      console.log(this.mode)
    },
    darkModeActivate() {
      document.querySelector('body').classList.toggle("dark")
    }
  },
  watch:{
    $route: {
      immediate:true,
      handler(to, from){
        document.title = to.meta.title || 'Sebastian Myrcha'
        document.querySelector('meta[name="description"]').setAttribute("content", to.meta.title || 'Sebastian Myrcha');
      }
    }
  }
}
</script>

<style>

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}


.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
