<template>
    <div class="tool box">
      <img :src="image" :alt="name" class="tool-icon">
      <p class="my-3 has-text-weight-bold">{{ name }}</p>
    </div>
</template>

<script>

export default {
  name: 'Tool',
  props: {
    image: String,
    name: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.tool-icon {
  width: auto;
  height: 64px;
  display: block;
  margin: 0 auto;
}

</style>
