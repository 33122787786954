<template>
  <transition name="fade" mode="out-in">
    <div class="intro p-6">
      <div class="container">
        <p class="is-size-4">Cześć, Jestem Sebastian</p>
        <h1 class="is-size-2">
          Freelancer, Projektant <br>
          interfejsów użytkownika,<br>
          Frontend Developer 
        </h1>
        <p class="is-size-4 max-width-75 my-4">
          Pomagam ludziom i firmom osiągnąć ich cele,<br>
          projektując i budując zorientowane na użytkownika <br>
          rozwiązania cyfrowe.
        </p>
        <a href="#projekty" class="button cta my-4" @click="(e) => goToSection(e)"><span>Moje projekty</span></a>
        <picture>
            <source :data-srcset="mode==='dark' ? darkImages.md: lightImages.md" media="(min-width: 1408px)" 
              type="image/png" 
              :srcset="mode==='dark' ? darkImages.md: lightImages.md"
            >
            <source :data-srcset="mode==='dark' ? darkImages.md: lightImages.md" media="(max-width: 768px)" 
              type="image/png" 
              :srcset="mode==='dark' ? darkImages.md: lightImages.md"
            >
            <img :src="mode==='dark' ? darkImages.md: lightImages.md" class="intro-image" :class="isLoaded===true ? 'loaded' : 'loading'" alt="Sebastian Myrcha  - Freelancer, Frontend Developer">
        </picture>
      </div>
    </div>
  </transition>
</template>

<script>
import { goToHashFast } from '@/assets/js/scroll.js'

export default {
  name: 'Intro',
  components: {

  },

  props: {
    mode: String
  },

  data() {
    return {
      darkImages: {"md": require("@/assets/images/sebastian-dark-md.png")},
      lightImages: {"md": require("@/assets/images/sebastian-md.png")},
      isLoaded: false
    }
  },

  methods: {
    showImages() {
      this.isLoaded = true
    },

    hideImages() {
      this.isLoaded = false
    },

    goToSection (e) {
      goToHashFast (e)
    }
  },

  mounted: function () {

  },

  watch:{
    mode: {
      immediate:true,
      handler(newVal, oldVal){
        console.log(newVal, oldVal)
        this.hideImages()
        setTimeout(() => {
          this.showImages()
        }, 400);
        
      }
    }
  }
  
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.intro {
  position: relative;
  top: 120px;
}

.intro-image {
  position: absolute;
  right: 20px;
  bottom: 0px;
  transition-property: opacity;
  opacity: 0;
}

.intro-image.loaded {
  opacity: 1;
  transition-duration: 2s;
}

@media screen and (max-width: 768px) {
  .intro-image {
    position: relative;
    right: 0px;
    bottom: 0px;
    margin: auto;
    text-align: center;
  }
}
</style>
