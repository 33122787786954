<template>
  <div class="switch" :class="position">
      <button class="switch-button" :class="position==='left' ? 'white' : null" @click="$emit('toggleSwitch')">{{ leftText }}</button>
      <button class="switch-button" :class="position==='right' ? 'white' : null" @click="$emit('toggleSwitch')">{{ rightText }}</button>
    </div>
</template>

<script>
export default {
    name: 'SwitchButton',
    props:{
        position: String,
        leftText: String,
        rightText: String
    },

    methods: {
        
    }
}
</script>

<style scoped>

.switch {
  display: flex;
  border-radius: 32px;
  background-color: rgba(200, 200, 200, 0.2);
  height: 64px;
  width: 320px;
  position: relative;
  margin: 1em auto;
}

.switch-button {
  background-color: transparent;
  width: 50%;
  border: 0px;
  z-index: 20;
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1.5em;
  color: #222;
  transition: all 0.3s ease-in-out;
}

body.dark .switch-button {
  color: #ededed;
}

.switch-button.white, body.dark .switch-button.white {
  color: #fff;
}

.switch::after {
  content: '';
  background: linear-gradient(90deg, var(--primary-color), var(--dark-primary));
  width: calc(50% - 8px);
  height: calc(100% - 8px);
  border-radius: 28px;
  position: absolute;
  top: 4px;
  left: 4px;
  box-shadow: 0 2px 8px 0 rgba(0,34,255,.08),0 2px 8px 0 rgba(0,11,80,.12);
  transition: transform .3s ease-in-out;
  transform: translateX(0px);
}

.switch.right::after {
  transform: translateX(158px);
}
</style>