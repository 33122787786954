<template>
    <a class="icon to-top-icon" 
      :class="!iconShow ? 'icon-hidden' : ''" 
      @click="scrollToTop" 
      @scroll.passive="toggleToTopIcon"
    >
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-circle-up" class="svg-inline--fa fa-arrow-circle-up fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm143.6 28.9l72.4-75.5V392c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V209.4l72.4 75.5c9.3 9.7 24.8 9.9 34.3.4l10.9-11c9.4-9.4 9.4-24.6 0-33.9L273 107.7c-9.4-9.4-24.6-9.4-33.9 0L106.3 240.4c-9.4 9.4-9.4 24.6 0 33.9l10.9 11c9.6 9.5 25.1 9.3 34.4-.4z"></path></svg>
    </a>
</template>

<script>
import { scrollIt } from '@/assets/js/scroll.js'

export default {
  name: 'ToTopLink',
  data() {
    return {
      iconShow: false
    }
  },
  methods: {        
        scrollToTop (e) {
            e.preventDefault()
            scroll({
              top: top,
              behavior: "smooth"
            })
        },

        toggleToTopIcon() {
            window.pageYOffset < 1000 ? this.iconShow = false : this.iconShow = true
        },
        
  },
  mounted () {
    window.addEventListener('scroll', this.toggleToTopIcon, true);
  },
  destroyed () {
    window.removeEventListener('scroll', this.toggleToTopIcon, true);
  }
}

</script>

<style>
.footer a.to-top-icon {
  position: fixed;
  bottom: 1em;
  right: 1em;
  width: 1.5em;
  height: 1.5em;
  margin: 0;
  z-index: 100;
  visibility: visible;
  opacity: 1;
  transition: all .3s ease-in-out;
  color: var(--primary-color)
}

.footer a.to-top-icon:hover {
  color: #999;
}

.footer a.to-top-icon.icon-hidden {
  visibility: hidden;
  opacity: 0;
}

@media screen and (max-width: 1024px) {
  .footer a.to-top-icon {
    bottom: 2.75em;
  }
  
  .footer a.to-top-icon:hover {
    color: #999;
  }
}
</style>