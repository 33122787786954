<template>
  <header class="header">
        <nav class="navbar is-fixed-top" id="nav" :class="isActive ? 'is-active' : ''">
            <div class="container">
                <div class="navbar-brand">
                  <router-link :to="{name: 'Home'}" class="navbar-item">
                    <span class="logo">
                      <img src="@/assets/logo.svg" height="50" width="38" alt="Sebastian Myrcha Frontend Developer">
                      <span class="ml-3 mt-1">
                        Sebastian Myrcha
                      </span>
                    </span>
                  </router-link>
                  <div @click="toggleMenu" class="navbar-burger" :class="isActive ? 'is-active' : ''" id="burger-menu">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              
                <div id="navbar" class="navbar-menu" :class="isActive ? 'is-active' : ''">              
                  <div class="navbar-end">
                    <a href="#projekty" class="navbar-item link-effect" @click="(e) => goToSection(e)">Projekty</a>
                    <a href="#o-mnie" class="navbar-item link-effect" @click="(e) => goToSection(e)">O mnie</a>
                    <a href="#kontakt" class="navbar-item link-effect" @click="(e) => goToSection(e)">Kontakt</a>
                    <SwitchMode :mode='mode' v-on="$listeners"/>                    
                  </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>

import SwitchMode from '@/components/SwitchMode.vue'
import { goToHashFast } from '@/assets/js/scroll.js'

export default {
  name: 'Header',
  components: {
    SwitchMode,
  },
  props: {
    mode: String
  },
  data(){
    return {
      isActive: false,
    }
  },

  methods: {
    toggleMenu () {
      this.isActive = !this.isActive
    },
    
    goToSection (e) {
      this.toggleMenu ()
      goToHashFast (e)
    }
  },
  watch:{
    $route: {
      immediate:true,
      handler(to, from){
        this.isActive=false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#nav {
  background: hsla(0, 0%, 100%, 0.9);
  backdrop-filter: saturate(180%) blur(20px);
  opacity: 1;
}

body.dark #nav {
  background-color: rgba(255, 255, 255, 0.1);
}

.logo {
  display: flex;
}
</style>
